export default Object.freeze({
  'image/png': 'file-image',
  'image/jpeg': 'file-image',
  'image/jpg': 'file-image',
  'image/gif': 'file-image',
  'application/pdf': 'file-pdf',
  'application/zip': 'file-archive',
  'text/plain': 'file-alt',
  'text/javascript': 'file-code',
  'application/json': 'file-code',
  'text/css': 'file-code',
  'text/html': 'file-code',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-word',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'file-powerpoint',
});
