import React from 'react';

import './caret.scss';

/**
 * A caret icon stolen from tailwind documentation
 */
const Caret = (props) => (
  <div className="bi bi-caret" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  </div>
);

export default React.memo(Caret);
